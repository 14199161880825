<script>
import Layout from "../_layouts/main";
import PageHeader from "@/components/page-header";
import Page from '../../components/report/log-activity'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Page
  },
  data() {
    return {
      title: "",
      state: ''
    };
  },
  mounted() {
    this.title = this.$t('활동') + ' ' + this.$t('로그')
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <Page />
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
